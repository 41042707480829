<script>
import httpClient from "@/services/http.service";

export default {
  name: "QRWinterScienceSchool",
  data() {
    return {
      qr: null,
    }
  },
  methods:{
    async getQR() {
      try {
        const {status, data} = await httpClient.get('winter_science_school/winter-visiting-journal/get-qr')
        if (status === 200) {
          this.qr = data
        }
      } catch (e) {
        this.$error({title: "Ошибка", details: e})
      }
    },
  },
  async mounted() {
    await this.getQR()
  }
}
</script>

<template>
  <section class="my-5">
    <h1 class="text-center">Cкан qr-код</h1>
    <div v-if="qr" class="text-center">
      <div v-if="qr.time_status == 1 && qr.date_status == 1">
        <h2 class="my-3">Отсканируйте qr-код для регистрации</h2>
        <img :src="`https://back.uib.kz/${qr.qr}`" height="350">
        <h2>Время генераций QR: {{ qr.current_time }}</h2>
      </div>
      <h3>Интервал сканирования QR: {{ qr.start_time }} - {{ qr.end_time }}</h3>
    </div>
    <div v-else>QR не сформирован</div>
  </section>
</template>

<style scoped>

</style>